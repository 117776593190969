import {ComponentRef, EditorSDK} from '@wix/platform-editor-sdk';
import {getPanelUrl} from '@wix/yoshi-flow-editor/utils';

export const handleOpenTranslationAssistantPanel = async (
  editorSDK: EditorSDK,
  componentRef: ComponentRef,
) => {
  console.log('Open text fitter panel triggered');

  await editorSDK.editor.openComponentPanel('', {
    componentRef,
    width: 228,
    url: getPanelUrl('TranslationAssistantPanel', 'TranslationAssistantPanel'),
  });
};
