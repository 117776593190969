import {AppExposedApis, ComponentRef, EditorSDK, EventType} from '@wix/platform-editor-sdk';
import {EditorReadyFn} from '@wix/yoshi-flow-editor';

import {
  handleAppInstalled,
  handleBeforeRemoveApp,
  handleOpenDashboard,
  handleOpenOnboarding,
  handleOpenPackagePicker,
  handleOpenTranslationAssistantPanel,
} from '@/handlers';

const TOKEN = ''; // editor platform said that it's unused and should be an empty string

export const editorReady: EditorReadyFn = async (
  editorSDK,
  _token,
  {firstInstall, origin},
  flowAPI,
) => {
  flowAPI.fedops.interactionStarted('editorReady');
  if (firstInstall) {
    flowAPI.fedops.interactionStarted('editorReady_firstInstall');
  }

  const appInstance = await editorSDK.info.getAppInstance(_token);
  await editorSDK.addEventListener(EventType.appInstalled, () =>
    handleAppInstalled(editorSDK, flowAPI.fedops, flowAPI.bi, origin.type, appInstance),
  );

  const editorSessionId = await editorSDK.editor.info.getEditorSessionId();
  const metaSiteId = await editorSDK.document.info.getMetaSiteId(TOKEN);
  flowAPI.bi?.updateDefaults({src: 89, msid: metaSiteId, esi: editorSessionId});

  if (firstInstall) {
    flowAPI.fedops.interactionEnded('editorReady_firstInstall');
  }
  flowAPI.fedops.interactionEnded('editorReady');
};

export const exports = (editorSDK: EditorSDK): AppExposedApis => {
  return {
    editor: {
      beforeRemoveApp: () => handleBeforeRemoveApp(editorSDK, TOKEN),
    },
    public: {
      openOnboarding: () => handleOpenOnboarding(editorSDK, TOKEN),
      openDashboard: (openDashboardPayload?: Record<string, any>) =>
        handleOpenDashboard(editorSDK, TOKEN, openDashboardPayload),
      openPackagePicker: (openPackagePickerPayload?: Record<string, any>) =>
        handleOpenPackagePicker(editorSDK, TOKEN, openPackagePickerPayload),
      openTranslationAssistantPanel: (componentRef: ComponentRef) =>
        handleOpenTranslationAssistantPanel(editorSDK, componentRef),
    },
  };
};
